/*******************************
         Site Overrides
*******************************/

.ui.button.secondary {
  color: @primaryColor;

  &:hover, &:focus {
    color: @primaryColor;
  }
}